import React, { memo, useEffect, useState } from 'react'
import useNft from '../hooks/useNft'
import { LoadingAnimation } from './Loading'
import './NftInput.css'

const OS_PLACEHOLDER = 'https://opensea.io/assets/<network>/<address>/<nftId>'
// TODO: wallet & imgContainer context?
export const NftInput = memo(({ osUrl, setOsUrl, onFileChange }: Props) => {
    const { name, imgUrl, progress, error } = useNft(osUrl)

    useEffect(() => {
        if (imgUrl) {
            onFileChange(imgUrl)
        }
    }, [imgUrl, onFileChange])
    return (
        <>
            <label htmlFor="osUrl">
                <p>
                Please paste the OpenSea NFT URL below to have the image of the NFT and link on the gift card
                </p>
                <input
                    className="os-input"
                    type="text"
                    name="openseaUrl"
                    id="osUrl"
                    value={osUrl}
                    onChange={(e) => setOsUrl(e.target.value)}
                    placeholder={OS_PLACEHOLDER}
                />
            </label>
            
            <div>
                {name}
                <br />
                {!imgUrl && !error && osUrl &&  <div className='loading-container'>
                    <LoadingAnimation />
                    {progress}
                    <p>Please wait while we download the NFT&apos;s image</p>
                </div>}
                
                {imgUrl && (
                    <img
                        src={imgUrl}
                        alt='NFT'
                        className='nft-image'
                    />
                )}
                { error && <p>{error}</p> }
                {error && <ManualNftInput onFileChange={onFileChange} />}
            </div>
        </>
    )
})

NftInput.displayName = 'NftInput'


interface Props {
    osUrl: string
    setOsUrl: (url: string) => void
    onFileChange: (url: string) => void
}

interface ManualProps {
    onFileChange: (url: string) => void
}
export const ManualNftInput = ({ onFileChange }: ManualProps) => {
    const [url, setUrl] = useState<string>('')

    useEffect(() => {
        onFileChange(url)
    }, [url, onFileChange])

    return (
        <div className="manualInput">
            <label htmlFor="imageUpload" className="inputLabel">
                You can upload an image to represent this NFT from your device
                if you&apos;re offline or if the image download failed for
                whatever reason.
            </label>
            <input
                type="file"
                id="imageUpload"
                placeholder="nft image"
                onChange={(e) => {
                    const files = e.target.files
                    if (!files) return
                    if (files.length >= 1) {
                        setUrl(URL.createObjectURL(files[0]))
                    }
                }}
            />
            {url && (
                <img alt='NFT' src={url} />
            )}
        </div>
    )
}
