import React, { FC } from 'react'
import { QRCode } from './QRCode'
import './SecretContainer.css'

interface Props {
    hiddenFromPrint?: boolean
    secretValue: string
    privateKey: string
}

export const SecretContainer : FC<Props> = ({hiddenFromPrint, secretValue, privateKey}: Props) => {
    
    return <div className='secretContainer'>
        <div className={hiddenFromPrint ? 'printHidden' : ''}>
        Secret phrase:
            <textarea
                contentEditable={false}
                name="mnemonic"
                id="mnemonic"
                cols={30}
                rows={4}
                readOnly
                defaultValue={secretValue}
            />
            <div className='qrCodeContainer'>
                <SecretQr name='Secret phrase' value={secretValue}/>
                <SecretQr name='Private Key' value={privateKey}/>
            </div>
        </div>
    </div>
}

interface QrProps {
    value: string
    name: string
}
const SecretQr: FC<QrProps> = ({value, name}) => {
    const id = `${name}code`
    return <>
        {value && <label
            className="qrcodeLabel"
            htmlFor={id}
        >
            {name}<br />
            <QRCode
                className="qrcode"
                id={id}
                text={value} />
        </label>
        }
    </>
}