import React, { FC } from 'react'
import './JustCheckbox.css'

interface Props {
    name: string
    checked?: boolean
    onChecked: () => void
    message?: string
}

export const JustCheckbox: FC<Props> = ({
    name,
    checked,
    onChecked,
    message
}) => {
    return (
        <label htmlFor={name} className='just-checkbox-label'>
            <input
                type="checkbox"
                name={name}
                id={`${name}Checkbox`}
                className='just-checkbox'
                checked={checked}
                onChange={() => {
                    onChecked()
                }}
            />{' '}
            {message}
        </label>
    )
}
