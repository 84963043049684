import React  from 'react'
import { NETWORK_SVGS } from '../constants/networks'
import './SupportedNetworks.css'

export const SupportedNetworks = () => {
    return <>   
        <p>Featured coins</p>
        <div className="supportned-networks">
            {Object.values(NETWORK_SVGS).map(
                (SvgComponent, index) => {
                    return <SvgComponent key={`network${index}`} />
                }
            )}
        </div>
    </>
}
