import React, { FC, useLayoutEffect, useRef } from 'react'
import {toDataURL} from 'qrcode'

interface Props {
    children?: never,
    text: string,
    className?: string,
    id?: string
}
export const QRCode: FC<Props> = ({text, className, id}: Props) => {
    const imgRef = useRef<HTMLImageElement>(null)

    useLayoutEffect(() => {
        const img = imgRef.current
        if (!img) return    
        toDataURL(text, {errorCorrectionLevel: 'Q', margin: 2}).then((url) => {
            img.src = url
        })
    }, [imgRef, text])
    return <img src="" alt={text} ref={imgRef} className={className} id={id}/>
}