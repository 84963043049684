import React, { FC, useMemo, useState } from 'react'
import { NETWORK_SVGS, SVGComponent } from '../constants/networks'
import './SelectedCoins.css'

interface Props {
    selection: number[]
    skip?: number
    onCoinChange?: (cid: number) => void
}

export const SelectedCoins: FC<Props> = ({selection, skip, onCoinChange}) => {
    const selectedCoins =  selection.filter(cid => cid !== skip)
    const selectedCoinSvgs = useMemo(() => mapToSvgs(selectedCoins), [selectedCoins])
    if (onCoinChange)
        return <div className='selected-coins'>
            { selectedCoinSvgs.map(([cid, SVGImage], index) => <SVGImage onClick={() => onCoinChange(cid)} className='selected-coin' key={`svg${index}`}/> ) }
        </div>
    return <div className='selected-coins'>
        { selectedCoinSvgs.map(([, SVGImage], index) => <SVGImage className='selected-coin' key={`svg${index}`}/> ) }
    </div>
}
const mapToSvgs = (coins: Array<number>): [number, SVGComponent][] => {
    return coins
        .map((cid: number) => [cid, NETWORK_SVGS[cid]] )
}

export const getSelectedCoins = (selection: Map<number, boolean> ): Array<number> => {
    return [...selection.entries()]
        .filter((entr) => entr[1])
        .map((entr) => entr[0])
}

interface OneCoinProps {
    selectedCoin: number
}

const OneCoin : FC<OneCoinProps> = ({selectedCoin}) => {
    const Coin = NETWORK_SVGS[selectedCoin]
    return <>
        <Coin className='network-image'/>
    </>
}

interface CoinDisplayProps {
    selection: Map<number, boolean>
    hideCoins?: boolean
}

export const CoinDisplay : FC<CoinDisplayProps> = ({selection, hideCoins}: CoinDisplayProps) => {
    const selectedCoins = getSelectedCoins(selection)
    const [selectedCoin, setSelectedCoin] = useState<number>(selectedCoins.length > 0 ? selectedCoins[0] : 1)

    return <>
        <OneCoin selectedCoin={selectedCoin} />
        { !hideCoins && <SelectedCoins selection={selectedCoins} skip={selectedCoin} onCoinChange={setSelectedCoin} /> }
    </>
}