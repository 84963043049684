import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Web3ContextProvider } from './context/Web3Context'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <Web3ContextProvider>
            <App />
        </Web3ContextProvider>
    </React.StrictMode>
)
