import React, { Children, FC, Fragment, ReactElement, ReactNode } from 'react'
import { FUCHSIA } from '../App'
import NeonButton from './NeonButton'
import './Steps.css'

interface StepProps {
    stepNum: number,
    previousStepNum?: number,
    condition?: boolean
    children?: ReactNode
    hideNextBtn?: boolean
    setCurrentStep?: (stepNum: number) => void
    customNextBtn?: ReactNode
    onNext?: () => void
}

interface StepsProps {
    currentStep?: number
    children?: ReactElement<StepProps>[] | ReactElement<StepProps>
    setCurrentStep: (stepNum: number) => void,
    hideCounter?: boolean
}

export const Steps: FC<StepsProps> = ({
    currentStep,
    children,
    setCurrentStep,
    hideCounter
}) => {
    if (!children) return <Fragment>No steps</Fragment>
    const charr = Children.toArray(children).map(
        (child) => child as ReactElement<StepProps>
    )
    const steps = Array.from(new Set(charr.map((child) => child.props.stepNum)))
    return        <>
        {hideCounter &&
        <div className="step-counter">
            {steps.map((stepNum) => {
                const classNames = stepNum === currentStep ? 'step-wrapper current-step' : 'step-wrapper'
                return (
                    <div
                        key={`k${stepNum}`}
                        className={classNames}>
                        {stepNum}
                    </div>
                )
            })}
        </div>
        }
        {charr
            .filter((child) => child.props?.stepNum === currentStep)
            .filter((child) => child.props.condition ?? true)
            .map((child) => React.cloneElement(child, { setCurrentStep }))}
    </>
    
}

export const Step: FC<StepProps> = ({
    stepNum,
    previousStepNum,
    children,
    setCurrentStep,
    hideNextBtn,
    customNextBtn,
    onNext
}) => {
    const nextButtonVisible = !hideNextBtn && !customNextBtn
    function toPreviousStep() {
        const prevStep = previousStepNum ?? (stepNum - 1)
        setCurrentStep && setCurrentStep(prevStep)
    }

    const hasPrev = stepNum > 0
    return <div className='step-container'>
        {hasPrev && (
            <div className='prev-btn' onClick={toPreviousStep}>
                <i
                    className="bi bi-caret-left"
                />
            </div>
        )}
        <div className="step-content">
            {children}
            <div className="step-button-container">
                {nextButtonVisible && (
                    <NeonButton
                        onClick={() => {
                            onNext && onNext()
                            setCurrentStep && setCurrentStep(stepNum + 1)
                        }}
                        color={FUCHSIA}
                        text="Next step"
                    />
                )}
                {customNextBtn && customNextBtn}
            </div>
        </div>
    </div>
}
