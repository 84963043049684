import React, { forwardRef, Ref } from 'react'
import './NeonButton.css'

const NeonButton = (props: NeonButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { onClick, disabled, text, color, width, nospacing } = props
    return (
        <button
            ref={ref}
            className="nb-common"
            onClick={onClick}
            disabled={disabled}
            style={{ backgroundColor: color, width: width, wordSpacing: nospacing ? 'unset' : '9999px'  }}
        >
            {text ?? 'Button'}
        </button>
    )
}

interface NeonButtonProps {
    children?: never
    disabled?: boolean
    onClick?: () => void
    text?: string
    color?: string,
    width?: string,
    nospacing?:boolean
}

export default forwardRef(NeonButton)
