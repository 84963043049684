import React, { useCallback, useEffect, useRef, useState } from 'react'
import './App.css'
import NeonButton from './components/NeonButton'
import { CoinSelector, SelectionRef } from './components/CoinSelector'
import { NftInput } from './components/NftInput'
import { Selection } from './components/RadioButton'
import useWallet from './hooks/useWallet'
import { Step, Steps } from './components/Steps'
import { PrintSpacer } from './components/PrintSpacer'
import { Cropper } from './components/Cropper'
import { SupportedNetworks } from './components/SupportedNetworks'
import { ControlPanel, DEFAULT_SETTINGS, Settings } from './components/ControlPanel'
import { SecretContainer } from './components/SecretContainer'
import { CoinDisplay, getSelectedCoins, SelectedCoins } from './components/SelectedCoins'
import { QRCode } from './components/QRCode'
import bgImage from './spacerBg.png'
import logo from './giveth-logo.png'
import poweredByButts from './pwrd-by-butts.png'

export const FUCHSIA = 'var(--accent)'
export const PURPLE = 'var(--purple)'
export const L_BLUE = 'var(--lblue)'
export const RED = 'var(--red)'
export const YELLOW = 'var(--yellow)'
export const GREEN = 'var(--green)'

function App() { 
    const [selection, setSelection] = useState<Selection>(Selection.NFT)
    const [nftOsUrl, setNftOsUrl] = useState<string>('')
    const [nftImgUrl, setNftImgUrl] = useState<string>('')
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [mnemonic, setMnemonic] = useState<string | undefined>()
    const [cpSettings, setCpSettings] = useState<Settings>(DEFAULT_SETTINGS)
    const [canvasUrl, setCanvasUrl] = useState<string | undefined>(undefined)
    const [coinSelection, setCoinSelection] = useState<Map<number, boolean>>(new Map())

    const wallet = useWallet(mnemonic)
    const ankrScanQrUrl = 'https://ankrscan.io/address/' + wallet.address

    const resetState = () => {
        setSelection(Selection.NFT)
        setNftOsUrl('')
        setNftImgUrl('')
        setCurrentStep(0)
        setMnemonic(undefined)
        setCpSettings(DEFAULT_SETTINGS)
    }

    useEffect(() => {
        setMnemonic(wallet.mnemonic?.phrase)
    }, [wallet.mnemonic?.phrase])

    useEffect(() => {
        const img = new Image()
        img.src = bgImage
    },[])

    const {crop: isCrop, replaceImg, seedHidden, hideCoins} = cpSettings

    const selectorRef = useRef<SelectionRef>(null)
    const onNextBtnClick = useCallback(() => {
        const sel = selectorRef.current?.selection() ?? new Map()
        setCoinSelection(sel)
    }, [setCoinSelection, selectorRef])

    return (
        <>
            <Steps currentStep={currentStep} setCurrentStep={setCurrentStep}>
                <Step stepNum={0} hideNextBtn={true}>
                    <img className='giveth-logo' src={logo} alt="givETH.art logo" />
                    <p>
                        givETH makes it easy and secure to gift an NFT or Crypto to even non-tech savvy friends, family or customers.
                    </p>
                    <NeonButton
                        onClick={() => {
                            setCurrentStep(1)
                        }}
                        width="50%"
                        color={FUCHSIA}
                        text="Start"
                    />
                    <p className='help-link hoverable'>
                        <a href="https://help.giveth.art" target="_blank" rel='noreferrer'>Click here for help topics and to learn more about how we make our system secure and free</a>
                    </p>
                    <SupportedNetworks/>
                    <a href='https://hottbutts.club/' className='hb-link' target='_blank' rel='noreferrer'>
                        <div className='pwrd-by-butts hoverable' />
                    </a>
                </Step>
                <Step stepNum={1} previousStepNum={0} hideNextBtn={true}>
                    <p>What would you like to send to this Gift Card?</p>
                    <div className='stacked-btn-container'>
                        <NeonButton
                            onClick={() => {
                                setSelection(Selection.NFT)
                                setCurrentStep(2)
                            }}
                            color={FUCHSIA}
                            text="NFT"
                        />
                        <NeonButton
                            onClick={() => {
                                setSelection(Selection.CRYPTO)
                                setCurrentStep(2)
                            }}
                            color={L_BLUE}
                            text="Crypto"
                        />
                        <NeonButton
                            onClick={() => {
                                setSelection(Selection.MIX)
                                setCurrentStep(2)
                            }}
                            color={GREEN}
                            text="Mix"
                        />
                    </div>
                    {/* <GiftCardTypeSelection selection={selection} ref={giftCardTypeRef} /> */}
                </Step>
                <Step
                    stepNum={2}
                    condition={selection === Selection.NFT}
                    hideNextBtn={!nftImgUrl}
                >
                    <NftInput
                        osUrl={nftOsUrl}
                        setOsUrl={setNftOsUrl}
                        onFileChange={setNftImgUrl}
                    />
                </Step>

                <Step stepNum={2} condition={selection === Selection.CRYPTO} onNext={onNextBtnClick}>
                    <p>Please select which crypto icon(s) you would like on your gift card</p>
                    <CoinSelector initialSelection={coinSelection} ref={selectorRef}/>
                </Step>

                <Step
                    stepNum={2}
                    condition={selection === Selection.MIX}
                    hideNextBtn={!nftImgUrl}
                    onNext={onNextBtnClick}>
                    <CoinSelector initialSelection={coinSelection} ref={selectorRef}/>

                    <NftInput
                        osUrl={nftOsUrl}
                        setOsUrl={setNftOsUrl}
                        onFileChange={setNftImgUrl}
                    />
                </Step>

                <Step stepNum={3} hideNextBtn={true}>
                    <p>To make your gift card, givETH uses a mnemonic code generator isolated to your browser.</p>
                    <p>Below is a securely generated seed phrase and public address we will use to make your gift card</p>
                    <textarea
                        name="mnemonic"
                        id="mnemonic"
                        value={mnemonic}
                        cols={30}
                        rows={10}
                        onChange={(e) => setMnemonic(e.target.value)}
                    />
                    <p>Public Address</p>
                    <p className='wallet-addy'>{wallet.address}</p>
                    
                    <div className='stacked-btn-container'>
                        <NeonButton
                            onClick={() => {
                                setMnemonic(undefined)
                            }}
                            color={L_BLUE}
                            nospacing={true}
                            text="Generate Random Wallet"
                        />
                        <p>
                            To learn more about our app&apos;s privacy and inability to save data, see our <a href="https://help.giveth.art" target="_blank" rel='noreferrer'>help page</a>
                        </p>
                        <NeonButton
                            onClick={() => {
                                setCurrentStep(4)
                            }}
                            // width="50%"
                            color={FUCHSIA}
                            nospacing={true}
                            text="Generate Gift Card"
                        />
                    </div>
                </Step>

                <Step
                    stepNum={4}
                    condition={selection === Selection.CRYPTO}
                    customNextBtn={ scratchButton() }>
                    <div className="container">
                        <CoinDisplay selection={coinSelection} hideCoins={hideCoins}/>
                        <div className='qrcode-container'>
                            <AnkrQr/>
                        </div>
                    </div>
                    <FinishFooter/>
                </Step>
                    
                <Step
                    stepNum={4}
                    customNextBtn={ scratchButton() }
                    condition={selection === Selection.NFT}>
                    <div className="container">
                        {nftImgUrl && !isCrop && !replaceImg && (
                            <img
                                src={nftImgUrl}
                                alt="NFT"
                                className="nftImg"
                            />
                        )}
                        {nftImgUrl && isCrop  && (
                            <Cropper
                                nftImgUrl={nftImgUrl}
                                onCropChange={setCanvasUrl}
                            />
                        )}

                        {nftImgUrl && isCrop && !replaceImg && (
                            <img className="nftImg" src={canvasUrl} alt="cropped nft" />
                        )}
                        
                        {(!nftImgUrl || replaceImg) && (
                            <CoinDisplay selection={coinSelection} hideCoins={true}/>
                        )}

                        <div className='qrcode-container'>
                            <OpenSeaQr/>
                            <AnkrQr/>
                        </div>
                    </div>
                    <FinishFooter/>
                </Step>
                <Step
                    stepNum={4}
                    customNextBtn={ scratchButton() }
                    condition={selection === Selection.MIX}>
                    <div className="container">
                        {nftImgUrl && !isCrop && (
                            <img
                                src={nftImgUrl}
                                alt="NFT"
                                className="nftImg"
                            />
                        )}
                        {nftImgUrl && isCrop && (
                            <Cropper
                                nftImgUrl={nftImgUrl}
                                onCropChange={setCanvasUrl}
                            />
                        )}

                        {nftImgUrl && isCrop && (
                            <img className="nftImg" src={canvasUrl} alt="cropped nft" />
                        )}

                        { !hideCoins && <SelectedCoins selection={getSelectedCoins(coinSelection)} /> }
                        
                        <div className='qrcode-container'>
                            <OpenSeaQr/>
                            <AnkrQr/>
                        </div>
                    </div>
                    <FinishFooter/>
                </Step>
            </Steps>
        </>
    )

    function FinishFooter() {
        return<> 
            <PrintSpacer/>
            <ControlPanel selection={selection} walletAddy={wallet.address} settings={cpSettings} onSettingsChange={(settings) => {
                setCpSettings(settings)
            } } />
        
            <SecretContainer secretValue={mnemonic ?? ''} privateKey={wallet.privateKey} hiddenFromPrint={seedHidden}/>
            <PrintSpacer />
        </>
    }

    function OpenSeaQr() {
        return <label
            className="qrcodeLabel"
            htmlFor="oscode"
        >
            OpenSea page <br />
            <QRCode
                className="qrcode"
                id="oscode"
                text={nftOsUrl} />
        </label>
    }

    function AnkrQr() {
        return <label className="qrcodeLabel" htmlFor="escode">
            AnkrScan page <br />
            <QRCode
                className="qrcode"
                id="escode"
                text={ankrScanQrUrl} />
        </label>
    }

    function scratchButton(): React.ReactNode {
        return <NeonButton
            onClick={() => resetState()}
            color={'#0BE9F6'}
            width="clamp(120px,30%,280px)"
            text="Start from scratch" />
    }
}

export default App
