import React, { ChangeEvent, forwardRef, Fragment, useCallback, useImperativeHandle, useRef } from 'react'
import { randomString } from '../utils'
import './RadioButton.css'

export enum Selection {
    NFT,
    CRYPTO,
    MIX
}


export interface RadioButtonRef {
    checked: () => boolean
    thisSelection: Selection
}


export const RadioButton = forwardRef<RadioButtonRef,RadioButtonProps>(({
    name,
    label,
    currentSelection,
    thisSelection,
    setSelection
}, ref) => {
    const inputId = randomString()
    const inputRef = useRef<HTMLInputElement>(null)
    const onClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (setSelection !== undefined) {
            e.target.checked && setSelection(thisSelection)
        }
    }, [setSelection, thisSelection])
    useImperativeHandle(ref, () => ({
        checked: () => inputRef.current?.checked ?? false,
        thisSelection
    }),[inputRef, thisSelection])
    
    return (
        <Fragment>
            <label htmlFor={inputId}>
                <input
                    ref={inputRef}
                    type="radio"
                    name={name}
                    id={inputId}
                    value={thisSelection}
                    defaultChecked={currentSelection === thisSelection}
                    onChange={onClick}
                />
                {label ?? 'No label'}
            </label>
        </Fragment>
    )
})

RadioButton.displayName = 'RadioButton'

interface RadioButtonProps {
    children?: never
    name: string
    label?: string
    currentSelection: Selection
    thisSelection: Selection
    setSelection?: (s: Selection) => void
}
