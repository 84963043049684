import React from 'react'
import './CopyButton.css'

interface Props {
    textToCopy: string
}

const copyToClipboard = (text: string) => {
    navigator.clipboard
        .writeText(text)
        .catch((err) => console.error('Error copying', err))
}

export const CopyButton = ({ textToCopy }: Props) => {
    // TODO: tooltip when copied
    return (
        <div className="copy-container">
            {textToCopy}{' '}
            <i
                className="bi bi-clipboard"
                onClick={() => copyToClipboard(textToCopy)}
            />
        </div>
    )
}
