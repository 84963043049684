import { Contract, JsonRpcProvider, toBeHex } from 'ethers'
import { useContext, useEffect, useState } from 'react'
import { Web3Context } from '../context/Web3Context'
import { NETWORK_RPC } from '../constants/rpc'
import { CHAIN_ID } from '../constants/chains'

export interface NFT {
  name: string;
  imgUrl: string;
  error: string;
  progress: string;
}

const genericAbi = [
    'function name() external view returns (string memory)',
    'function tokenURI(uint256 tokenId) external view returns (string memory)',
    'function uri(uint256 tokenId) external view returns (string memory)',
    'function baseURI() external view returns (string)',
    'function totalSupply() external view returns (uint256)',
    'function balanceOf(address owner) external view returns (uint256)',
]

const isValidUrl = (url: string) => {
    let u
    try {
        u = new URL(url)
    } catch {
        return false
    }
    return u.protocol === 'http:' || u.protocol === 'https:'
}

const getMetadataUri = async (
    contract: Contract,
    nftId: string
): Promise<string> => {
    const bigNumberId = BigInt(nftId)
    let metadataUri = ''
    try {
        metadataUri = await contract.tokenURI(bigNumberId)
    } catch (error) {
        metadataUri = (await contract.uri(bigNumberId)).replace(
            '0x{id}',
            toBeHex(bigNumberId)
        )
    }
    return metadataUri
}

// TODO: nft cache context??
export default function useNft(osUrl: string | undefined): NFT {
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [imgUrl, setImgUrl] = useState('')
    const [error, setError] = useState('')
    const [progress, setProgress] = useState('')
    const web3ctx = useContext(Web3Context)

    useEffect(() => {
        if (!osUrl) return

        // TODO: fix, it bad
        if (!osUrl.includes('opensea')) {
            return
        }
        const [network, address, nftId] = osUrl
            .substring(osUrl.indexOf('assets') + 7)
            .split('/')

        if (!network || !address || !nftId) return
        setImgUrl('')
        setUrl('')
        setError('')
        setProgress('Connecting to provider...')
        const rpc = NETWORK_RPC[CHAIN_ID[network]]
        if (!rpc) {
            setError(`${network} not supported`)
            return
        }
        // TODO: optimize this lol (memoization of providers)
        const provider = new JsonRpcProvider(rpc)
        // const provider = new ethers.providers.InfuraProvider(network, "e6192a37274744fdb07a476e465d958b")
        const contract: Contract = new Contract(address, genericAbi, provider)

        contract
            .name()
            .then((n: string) => {
                setName(`${n} #${nftId}`)
            })
            .catch((error: unknown) => {
                console.warn('Error getting NFT name from contract', error)
            })
        setProgress('Fetching NFT metadata uri...')
        getMetadataUri(contract, nftId)
            .then((uri: string) => {
                setUrl(uri)
                setProgress('Fetched NFT metadata uri!')
            })
            .catch((error: unknown) => {
                setError('Error getting NFT metadata uri from contract')
                console.warn(error)
            })
        web3ctx.overrideProvider(provider)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [osUrl])

    useEffect(() => {
        if (!url) return
        if (!isValidUrl(ipfsTransform(url))) return
        setProgress('Fetching NFT image...')
        fetchImage(url).then(([imageUrl, err]) => {
            if (err) console.error(err)
            setImgUrl(imageUrl)
            setError(err)
            setProgress('Fetched NFT image!')
        })
    }, [url])

    return { name, imgUrl, progress, error }
}

const corsHack = (url: string) => {
    return `https://cors-hack.fly.dev/${ipfsTransform(url)}`
    // return ipfsTransform(url)
}

const ipfsTransform = (ipfsUrl: string) => {
    return ipfsUrl.replace('ipfs://', 'https://ipfs.io/ipfs/')
}

async function fetchImage(metadataUrl: string): Promise<[string, string]> {
    let metadata
    try {
        metadata = await fetch(corsHack(metadataUrl), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
    } catch (error) {
        return ['', `Error fetching NFT metadata from ${metadataUrl}`]
    }

    const metadataResponse = await metadata
        .json()
        .catch(async (err) => {
            console.error(err)
            return ['', `Error reading NFT metadata from ${metadataUrl}`]
        })
        .then((obj) => [obj.image, ''])

    if (metadataResponse[0]) {
        try {
            const imgResponse = await fetch(corsHack(metadataResponse[0]))
            const imageBlob = await imgResponse.blob()
            return [URL.createObjectURL(imageBlob), '']
        } catch (err) {
            console.error(err)
            return ['', `Error getting NFT image from ${metadataResponse[0]}`]
        }
    }

    return [
        '',
        metadataResponse[1] !== '' ? metadataResponse[1] : 'Unable to get image',
    ]
}
