import { ChainId } from './ChainId'

export const NETWORK_RPC: Record<number, string> = {
    [ChainId.ARBITRUM]: 'https://rpc.ankr.com/arbitrum/',
    [ChainId.ARBITRUM_NOVA]: 'https://rpc.ankr.com/arbitrumnova',
    [ChainId.AVALANCHE]: 'https://rpc.ankr.com/avalanche/',
    [ChainId.BSC]: 'https://rpc.ankr.com/bsc/',
    [ChainId.ETHEREUM]: 'https://rpc.ankr.com/eth/',
    [ChainId.CELO]: 'https://rpc.ankr.com/celo/',
    [ChainId.FANTOM]: 'https://rpc.ankr.com/fantom/',
    [ChainId.HARMONY]: 'https://rpc.ankr.com/harmony/',
    [ChainId.MOONBEAM]: 'https://rpc.ankr.com/moonbeam/',
    [ChainId.OPTIMISM]: 'https://rpc.ankr.com/optimism/',
    [ChainId.MATIC]: 'https://rpc.ankr.com/polygon/',
}
