import { ChainId } from './ChainId'
import arbitrumIcon from '../icons/arbitrum.svg'
import arbitrumNovaIcon from '../icons/arbitrumnova.svg'
import avalancheIcon from '../icons/avalanche.svg'
import bscIcon from '../icons/bsc.svg'
import celoIcon from '../icons/celo.svg'
import ethereumIcon from '../icons/ethereum.svg'
import fantomIcon from '../icons/fantom.svg'
import harmonyIcon from '../icons/harmony.svg'
import moonbeamIcon from '../icons/moonbeam.svg'
import optimismIcon from '../icons/optimism.svg'
import polygonIcon from '../icons/polygon.svg'

import { ReactComponent as ArbitrumSvg } from '../icons/arbitrum.svg'
import { ReactComponent as ArbitrumNovaSvg } from '../icons/arbitrumnova.svg'
import { ReactComponent as AvalancheSvg } from '../icons/avalanche.svg'
import { ReactComponent as BscSvg } from '../icons/bsc.svg'
import { ReactComponent as CeloSvg } from '../icons/celo.svg'
import { ReactComponent as EthereumSvg } from '../icons/ethereum.svg'
import { ReactComponent as FantomSvg } from '../icons/fantom.svg'
import { ReactComponent as HarmonySvg } from '../icons/harmony.svg'
import { ReactComponent as MoonbeamSvg } from '../icons/moonbeam.svg'
import { ReactComponent as OptimismSvg } from '../icons/optimism.svg'
import { ReactComponent as PolygonSvg } from '../icons/polygon.svg'
import { FC, SVGProps } from 'react'

export const NETWORK_ICON: Record<number, string> = {
    [ChainId.ETHEREUM]: ethereumIcon,
    [ChainId.ROPSTEN]: ethereumIcon,
    [ChainId.RINKEBY]: ethereumIcon,
    [ChainId.GÖRLI]: ethereumIcon,
    [ChainId.KOVAN]: ethereumIcon,
    [ChainId.FANTOM]: fantomIcon,
    [ChainId.FANTOM_TESTNET]: fantomIcon,
    [ChainId.BSC]: bscIcon,
    [ChainId.BSC_TESTNET]: bscIcon,
    [ChainId.MATIC]: polygonIcon,
    [ChainId.MATIC_TESTNET]: polygonIcon,
    [ChainId.XDAI]: ethereumIcon,
    [ChainId.ARBITRUM]: arbitrumIcon,
    [ChainId.ARBITRUM_TESTNET]: arbitrumIcon,
    [ChainId.MOONBEAM_TESTNET]: moonbeamIcon,
    [ChainId.AVALANCHE]: avalancheIcon,
    [ChainId.AVALANCHE_TESTNET]: avalancheIcon,
    [ChainId.HECO]: celoIcon,
    [ChainId.HECO_TESTNET]: celoIcon,
    [ChainId.HARMONY]: harmonyIcon,
    [ChainId.HARMONY_TESTNET]: harmonyIcon,
    [ChainId.OKEX]: ethereumIcon,
    [ChainId.OKEX_TESTNET]: ethereumIcon,
    [ChainId.CELO]: celoIcon,
    [ChainId.PALM]: celoIcon,
    [ChainId.MOONRIVER]: ethereumIcon,
    [ChainId.FUSE]: ethereumIcon,
    [ChainId.TELOS]: ethereumIcon,
    [ChainId.MOONBEAM]: moonbeamIcon,
    [ChainId.OPTIMISM]: optimismIcon,
    [ChainId.KAVA]: ethereumIcon,
    [ChainId.METIS]: ethereumIcon,
    [ChainId.ARBITRUM_NOVA]: arbitrumNovaIcon,
}

export type SVGComponent = FC<SVGProps<SVGSVGElement>>;

export const NETWORK_SVGS: Record<number, SVGComponent> = {
    [ChainId.ARBITRUM]: ArbitrumSvg,
    [ChainId.ARBITRUM_NOVA]: ArbitrumNovaSvg,
    [ChainId.AVALANCHE]: AvalancheSvg,
    [ChainId.BSC]: BscSvg,
    [ChainId.ETHEREUM]: EthereumSvg,
    [ChainId.CELO]: CeloSvg,
    [ChainId.FANTOM]: FantomSvg,
    [ChainId.HARMONY]: HarmonySvg,
    [ChainId.MOONBEAM]: MoonbeamSvg,
    [ChainId.OPTIMISM]: OptimismSvg,
    [ChainId.MATIC]: PolygonSvg,
}

export const NETWORK_LABEL: Record<number, string> = {
    [ChainId.ETHEREUM]: 'Ethereum',
    [ChainId.RINKEBY]: 'Rinkeby',
    [ChainId.ROPSTEN]: 'Ropsten',
    [ChainId.GÖRLI]: 'Görli',
    [ChainId.KOVAN]: 'Kovan',
    [ChainId.FANTOM]: 'Fantom',
    [ChainId.FANTOM_TESTNET]: 'Fantom Testnet',
    [ChainId.MATIC]: 'Polygon',
    [ChainId.MATIC_TESTNET]: 'Polygon Testnet',
    [ChainId.XDAI]: 'Gnosis',
    [ChainId.ARBITRUM]: 'Arbitrum',
    [ChainId.ARBITRUM_TESTNET]: 'Arbitrum Testnet',
    [ChainId.BSC]: 'BSC',
    [ChainId.BSC_TESTNET]: 'BSC Testnet',
    [ChainId.MOONBEAM_TESTNET]: 'Moonbase',
    [ChainId.AVALANCHE]: 'Avalanche',
    [ChainId.AVALANCHE_TESTNET]: 'Fuji',
    [ChainId.HECO]: 'HECO',
    [ChainId.HECO_TESTNET]: 'HECO Testnet',
    [ChainId.HARMONY]: 'Harmony',
    [ChainId.HARMONY_TESTNET]: 'Harmony Testnet',
    [ChainId.OKEX]: 'OKEx',
    [ChainId.OKEX_TESTNET]: 'OKEx',
    [ChainId.CELO]: 'Celo',
    [ChainId.PALM]: 'Palm',
    [ChainId.MOONRIVER]: 'Moonriver',
    [ChainId.FUSE]: 'Fuse',
    [ChainId.TELOS]: 'Telos EVM',
    [ChainId.MOONBEAM]: 'Moonbeam',
    [ChainId.OPTIMISM]: 'Optimism',
    [ChainId.KAVA]: 'Kava',
    [ChainId.METIS]: 'Metis',
    [ChainId.ARBITRUM_NOVA]: 'Arbitrum Nova',
}
