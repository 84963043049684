import React, { FC, ReactNode } from 'react'
import './PrintSpacer.css'
import bgImage from '../spacerBg.png'

interface Props {
    children?: ReactNode
    hideBackground?: boolean
}

export const PrintSpacer: FC<Props> = ({ children, hideBackground }) => {
    return (
        <div className="print-spacer">
            { children && <div className="hackk">{children}</div> }
            {!hideBackground && (
                <div className="spacer-bg">
                    <img alt='background' src={bgImage} />
                </div>
            )}
        </div>
    )
}
