import { ethers } from 'ethers'
import React, { createContext, FC, ReactNode, useState } from 'react'

interface Props {
    children?: ReactNode
}

interface Web3ContextHolder {
    provider?: ethers.Provider
    overrideProvider: (p: ethers.Provider) => void
}
const NotImplemented = () => {
    console.warn('Not implemented')
}
export const Web3Context = createContext<Web3ContextHolder>({
    provider: undefined,
    overrideProvider: NotImplemented
})

export const Web3ContextProvider: FC<Props> = ({ children }) => {
    const [provider, setProvider] = useState<
        ethers.Provider | undefined
    >()
    return (
        <>
            <Web3Context.Provider
                value={{
                    provider,
                    overrideProvider: (p) => {
                        setProvider(p)
                    }
                }}
            >
                {children && children}
            </Web3Context.Provider>
        </>
    )
}
