import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { NETWORK_LABEL, NETWORK_SVGS, SVGComponent } from '../constants/networks'
import { NETWORK_RPC } from '../constants/rpc'
import './CoinSelector.css'

interface Props {
    children?: never
    initialSelection: Map<number, boolean>
}

interface Coin {
    label: string,
    icon: SVGComponent,
    id: number
}

interface CoinLabelProps {
    children?: never
    coin: Coin
    checked?: boolean
}

const toCoin = (chainId: number): Coin => {
    return {
        label: NETWORK_LABEL[chainId],
        icon: NETWORK_SVGS[chainId],
        id: chainId
    }
}

interface CoinLabelRef {
    status: () => {
        isChecked: boolean
        coinId: number
    }
}

const CoinLabel = forwardRef<CoinLabelRef, CoinLabelProps>(({coin, checked}, ref) => {
    const coinLabel = coin.label
    const CoinIcon = coin.icon
    const checkboxRef = useRef<HTMLInputElement>(null)
    const labelRef = useRef<HTMLLabelElement>(null)
    const id = `${coinLabel.toLowerCase()}Checkbox`
    useImperativeHandle(ref, 
        () => ({ 
            status: () => ({
                isChecked: checkboxRef.current?.checked ?? false,
                coinId: coin.id
            })
        })
        , [checkboxRef, coin])

    return (
        <label htmlFor={id} key={coin.id} ref={labelRef} data-checked={checked ?? false} className='coin-label'>
            <input
                type="checkbox"
                className="coin-checkbox"
                name="cryptoCheckbox"
                id={id}
                ref={checkboxRef}
                defaultChecked={checked ?? false}
                onChange={(e) => {
                    const label = labelRef.current
                    if (!label) return

                    label.dataset.checked=`${e.target.checked}`
                }}
            />
            <CoinIcon className='coin-icon'/>
            <span className="coin-label-text">
                {coinLabel}
            </span>
        </label>
    )
})
CoinLabel.displayName = 'CoinLabel'


interface SelectionRef {
    selection: () => Map<number, boolean>
}

const CoinSelector = forwardRef<SelectionRef, Props>(({initialSelection}, ref) => {
    const coinRefs = useRef<(CoinLabelRef | null)[]>([])

    useImperativeHandle(ref, () =>  ({
        selection: () => 
            new Map(coinRefs.current.filter(notEmpty)
                .map((c) => c.status())
                .map((s) => [s.coinId, s.isChecked]))         
    }), [coinRefs])

    const coins = useMemo(() => Object.keys(NETWORK_RPC)
        .map((cid) => parseInt(cid))
        .map(toCoin),[])

    const coinLabels = useMemo(() => {
        return coins
            .map((coin) => {
                const id = `${coin.label.toLowerCase()}Checkbox`
                
                return (
                    <CoinLabel ref={(r) => coinRefs.current.push(r)} key={id} coin={coin} checked={initialSelection.get(coin.id)}/>
                )
            })
    }, [coins, initialSelection])
    // TODO: testnet exlusion
    return (
        <div className="coin-selector">
            {coinLabels}
        </div>
    )
})

CoinSelector.displayName = 'CoinSelector'



function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined
}

export {CoinSelector, CoinLabel }
export type { SelectionRef } 