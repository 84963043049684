import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import ReactCrop, {
    centerCrop,
    Crop,
    makeAspectCrop,
    PixelCrop
} from 'react-image-crop'
import { canvasPreview } from '../canvasPreview'
import './Cropper.css'
import 'react-image-crop/dist/ReactCrop.css'

export const Cropper = ({ nftImgUrl, onCropChange }: Props) => {
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [open, setOpen] = useState<boolean>(true)
    const imgRef = useRef<HTMLImageElement>(null)
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)

    const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = centerCrop(
            makeAspectCrop({ unit: '%', width: 90 }, 1, width, height),
            width,
            height
        )
        setCrop(crop)
    }

    const onArrowClick = () => {
        setOpen((o) => !o)
    }

    useEffect(() => {
        if (completedCrop && imgRef.current && previewCanvasRef.current) {
            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop
            )
            const dataUrl = previewCanvasRef.current.toDataURL()
            onCropChange(dataUrl)
        }

    }, [completedCrop, previewCanvasRef, onCropChange])
    const classes = 'cropper ' + (!open ? 'cropper-hidden' : '')
    return (
        <>
            <canvas
                ref={previewCanvasRef}
                style={{
                    objectFit: 'contain',
                    display: 'none'
                }}
            />
            <div className="cropper-arrow" onClick={onArrowClick}>
                {open ? <HideArrow /> : <ShowArrow />}
            </div>
            <div className={classes}>
                <p>Crop your image here!</p>
                <ReactCrop
                    crop={crop}
                    onChange={setCrop}
                    onComplete={setCompletedCrop}
                    ruleOfThirds={true}
                    aspect={1}
                >
                    <img src={nftImgUrl} alt='NFT' ref={imgRef} onLoad={onImageLoad} />
                </ReactCrop>
            </div>
        </>
    )
}

interface Props {
    nftImgUrl: string
    onCropChange: (dataUrl: string) =>  void,
}

const HideArrow = () => {
    return (
        <>
            <i className="bi bi-arrow-down"></i>
            Hide
        </>
    )
}

const ShowArrow = () => {
    return (
        <>
            <i className="bi bi-arrow-up"></i>
            Show
        </>
    )
}
