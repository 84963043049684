import { L_BLUE, PURPLE, RED, YELLOW } from '../App'
import './Loading.css'
import React from 'react'

const Dot = (props: DotProps) => {
    return <hr style={props} className="loader-anim" />
}

export const LoadingAnimation = ({ width, height }: AnimationProps) => {
    const styles = { width: width ?? '64px', height: height ?? '64px' }
    return (
        <div className="loading-bg" style={{ ...styles }}>
            <Dot backgroundColor={PURPLE} animationDelay="-1.5s" />
            <Dot backgroundColor={L_BLUE} animationDelay="-1s" />
            <Dot backgroundColor={RED} animationDelay="-0.5s" />
            <Dot backgroundColor={YELLOW} />
        </div>
    )
}

interface AnimationProps {
    height?: string
    width?: string
}

interface DotProps {
    backgroundColor: string
    animationDelay?: string
}
