import React, { FC } from 'react'
import { CopyButton } from './CopyButton'
import { JustCheckbox } from './JustCheckbox'
import { Selection } from './RadioButton'
import { Tooltip } from './Tooltip'
import './ControlPanel.css'

interface Props {
    walletAddy: string
    selection: Selection
    settings: Settings
    onSettingsChange: (settings: Settings ) => void
}

export type Settings = {seedHidden: boolean, crop: boolean, replaceImg: boolean, hideCoins: boolean}
export const DEFAULT_SETTINGS: Settings = {seedHidden: false, crop: false, replaceImg: false, hideCoins: false}

export const ControlPanel: FC<Props> = ({walletAddy, selection, settings, onSettingsChange} : Props) => {
    const isNft = selection === Selection.NFT 
    const isMix = selection === Selection.MIX 
    const isCrypto = selection === Selection.CRYPTO
    const isCrop = isMix || isNft
    const isHide = isCrypto || isMix

    const {seedHidden, crop, replaceImg, hideCoins} = settings

    return <>
        <div className="printHidden user-box">
            {isCrypto && <p>You can switch featured Coin image by clicking on the smaller ones.</p>}
            <p>
                                You can now transfer the NFT and/or crypto
                                currencies to below address
            </p>
            <CopyButton textToCopy={walletAddy} />

            <JustCheckbox
                name="hideSeed"
                onChecked={() => {
                    onSettingsChange({...DEFAULT_SETTINGS, seedHidden: !seedHidden })
                }}
                checked={seedHidden}
                message="Check this if you want to hide secret phrase from print"
            />
            {isCrop && (
                <JustCheckbox
                    name="cropRectangle"
                    onChecked={() => {
                        onSettingsChange({...DEFAULT_SETTINGS, crop:!crop })
                    }}
                    checked={crop}
                    message="Check this if you want to crop the NFT image"
                />
            )}
            {isNft && (
                <JustCheckbox
                    name="replaceImg"
                    onChecked={() => {
                        onSettingsChange({...DEFAULT_SETTINGS, replaceImg: !replaceImg })
                    }}
                    checked={replaceImg}
                    message="Replace NFT image with chain icon"
                />
            )}
            {isHide && (
                <JustCheckbox
                    name="hideCoins"
                    onChecked={() => {
                        onSettingsChange({...DEFAULT_SETTINGS, hideCoins: !hideCoins })
                    }}
                    checked={hideCoins}
                    message="Hide coin icons next to the featured image"
                />
            )}
            <Tooltip label="This will open a print dialog with a custom template!">
                {/* <NeonButton onClick={() => { window.print() }} color={FUCHSIA} text='Print' /> */}
                <button
                    onClick={() => {
                        window.print()
                    }}
                    className="nb-common print-btn"
                >
                                    Print
                </button>
            </Tooltip>
        </div>
    </>
}
